
.input{
    width: 100%;
    padding:  10px;
    border-radius: 5px ;
    border:  1px solid gray;
}
.formikerror{
    color: red !important;
    font-weight: 500;
    margin-top: 5px;

}
.sportmodalBody{
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    padding-left: 50px !important;
    padding-right: 50px !important;
}
.image {
    
    opacity: 1;
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 5px;
}
.sportuploadImageContainer{
    width: 90%;
    /* height: 150px; */
    margin-right: auto;
    position: relative;
    overflow: hidden;
}
.sportuploadImageContainer:hover .image{
    opacity: 0.3;
}
.sportuploadImageContainer .overlay  {
    display: flex;
    column-gap: 5px;
    position: absolute;
    opacity: 0;
    top: 25%;
    left: 8%;
    transition: .5s ease;
    -ms-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
    text-align: center;
}
.sportuploadImageContainer:hover .overlay {
    opacity: 1;
}
.overlayBtn{
    font-size: x-large;
    cursor: pointer;
}
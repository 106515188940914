body a {
  color: #ff3505;
}

b,
strong {
  color: #495057;
}

th,
td {
  vertical-align: middle;
}

.page-content {
  padding: calc(70px + 10px) calc(24px * 0.75) 60px calc(24px * 0.75) !important;
}

.logout_button {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #3e3b3b;
  width: 100%;
  text-align: center;
  padding: 12px 20px;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  font-size: 13px;
  transition: all 0.4s;
}

.logout_button i {
  min-width: 2rem;
}

.sidebar-enable .logout_button span {
  display: none;
}

.logout_button span {
  display: inline-block;
}

/* body[data-sidebar="dark"] #sidebar-menu ul li.setting-active a, body[data-sidebar="dark"] #sidebar-menu ul li.setting-active a i {
    background: #000000 !important;
    color: #a6b0cf !important;
}
body[data-sidebar="dark"] #sidebar-menu ul li.mm-active a,body[data-sidebar="dark"] #sidebar-menu ul li:hover a, body[data-sidebar="dark"] #sidebar-menu ul li.mm-active a i, .logout_button:hover {
    color: #fff;
    background: #ed1c24;
} */

ol.breadcrumb.m-0 {
  display: none;
}

.inner-content {
  bottom: 0;
  left: 0;
}

.nft-category-image {
  height: 170px;
  overflow: hidden;
}

.nft-category-image img {
  height: 100%;
  object-fit: cover;
}

.background-user-image {
  background-image: url("../images/background-user.png");
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: -6%;
}

body[data-sidebar="dark"] .mm-active > a i {
  vertical-align: middle;
  font-size: 25px;
}

body[data-sidebar="dark"] .mm-active > a {
  color: #ffffff !important;
  font-size: 16px;
}

.font-theme-color {
  color: #ff3505 !important;
}

/* Slider */
.slick-initialized {
  padding: 0px !important;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.category-background {
  background-image: url("https://image.khaleejtimes.com/?uuid=1c6c087b-a5d5-5e37-9dcb-9293b0df11e4&function=cropresize&type=preview&source=false&q=75&crop_w=0.98472&crop_h=0.99999&x=0.00764&y=0&width=1200&height=475");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.account-pages {
  position: relative;
  z-index: 1;
}

.account-pages:after {
  background-image: url(https://cdn.fansshare.com/photo/hdwallpapersformobile/dark-blue-abstract-widescreen-hd-wallpaper-beraplancom-dark-abstract-wallpaper-hd-wallpapers-android-for-mobile-free-download-background-iphone-desktop-hd-wallpapers-for-iphone-970699686.jpg);
  /* background: #464748; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  content: "";
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: -50px;
  left: 0;
  z-index: -1;
  /* opacity: 0.5; */
}

h5,
.h5,
h4,
.h4 {
  font-size: 1.01562rem !important;
  color: #0058d1 !important;
}

.font-clr {
  color: #ff3505 !important;
}
.changepassword .eyediv input {
  position: relative;
}
.changepassword .eyediv .eye {
  position: absolute;
  top: 43px;
  right: 24px;
  width: 20px;
}
.card-title {
  color: #495057 !important;
}

.simplebar-content-wrapper ul.metismenu::-webkit-scrollbar {
  width: 5px;
}

.simplebar-content-wrapper ul.metismenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.simplebar-content-wrapper ul.metismenu::-webkit-scrollbar-thumb {
  background-color: #576078;
  outline: 0;
  border-radius: 30px;
}
input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #ced4da !important;
}
.bg-secondary,
.btn-secondary, .btn-primary, .bg-primary {
  background-color: #0f172a !important;
  border-color: #0f172a !important;
}
.modalHighlight .lkjDVC{
  min-width: 250px !important;
}
.modalHighlight .iNitOb{
  min-width: 250px !important;
}

.input{
    width: 100%;
    padding:  10px;
    border-radius: 5px ;
    border:  1px solid gray;
}
.formikerror{
    color: red !important;
    font-weight: 500;
    margin-top: 5px;

}
.modalHighlight .sportmodalBody{
    padding-left: 25px !important;
}   
.image {
    
    opacity: 1;
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 5px;
}
.sportuploadImageContainer{
    width: 90%;
    margin-right: auto;
    position: relative;
    overflow: hidden;
}
.sportuploadImageContainer:hover .image{
    opacity: 0.3;
}
.modalHighlight .sportuploadImageContainer .overlay  {
    display: flex;
    column-gap: 5px;
    position: absolute;
    opacity: 0;
    top: 16%;
    left: 27%;
    transition: .5s ease;
    -ms-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
    text-align: center;
}
.sportuploadImageContainer:hover .overlay {
    opacity: 1;
}
.overlayBtn{
    font-size: x-large;
    cursor: pointer;
}

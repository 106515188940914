.rarities  .lkjDVC{
height: 100px !important;
}
.rarities .iNitOb{
    height: 100px !important;
    }
    .rarityImages{
        width: 240px;
        border: 1px solid gray;
    padding: 6px;
    border-radius: 6px;
    margin-bottom: 20px;
    }
    .sportfilediv video{
        width: 240px;
        border: 1px solid gray;
    padding: 6px;
    border-radius: 6px;
    margin-bottom: 20px;
    }
h1{
    color: black;
}
.input-line-div{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.input{
    width: 85%;
    padding:  10px;
    border-radius: 5px ;
    border:  1px solid gray;
}
label{
    font-size: 16px;
}


.uploadImageContainer{
    width: 90%;
    /* height: 150px; */
    margin-right: auto;
    position: relative;
    overflow: hidden;
}
.uploadImageContainer:hover .image{
    opacity: 0.3;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 5px;
}
.uploadImageContainer .overlay  {
    display: flex;
    column-gap: 5px;
    position: absolute;
    opacity: 0;
    top:10%;
    left:90%;
    transition: .5s ease;
    -ms-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
    text-align: center;
}
.uploadImageContainer:hover .overlay {
    opacity: 1;
}
.overlayBtn{
    font-size: x-large;
    cursor: pointer;
}
.banner .dmajdp .file-types {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
.banner .sc-bcXHqe {
    height: 200px !important;
    display: flex;
    flex-direction: column;
}
.banner .dmajdp {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.banner .card{
    height: 100%;
}
.submitbtn{
    padding: 5px 20px;
    border: none;
    background-color: #ff2c00;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 500;
}
.submitbtn:hover{
    transform: translateY(-2px);
}
.banner .btn-div{
    display: flex;
    justify-content: center;
}
.error{
    color: #ff2c00;
    font-size: 14px;
}


.create-vendor .invalid-feedback{
    display: block !important;
}
.custom-error-val .invalid-feedback{
    display: block !important;
}
.toast-top-right{
    background-color: white !important;
}
.mytoggle input[switch] + label {
    width: 82px;
}
.mytoggle input[switch]:checked + label:after {
    left: 59px;
}
.dashboard-box .card-body {
    padding: var(--bs-card-spacer-y) 14px;
}
.dashboard-box .card {
    margin-bottom: 0px !important;
}
.dashboard-table table td{
    padding: 6px 0px;
}
.search-box .form-control {
    border-radius: 4px !important;
}
.custom-form .jodit-wysiwyg{
    min-height: 301px !important;
}

// Custome CSS


.cstm_btn_color, .submitbtn{
    background-color: #0f172a !important;
    color: #fff !important;
  }
*{
    font-family: 'Poppins' !important;
}
p, td.pivoted, td, th{
    font-size: 13px !important;
    color: #495057 !important;
}
td.pivoted, td, th{
    padding: 12px !important;
}
.color_picker{
    width: 35px !important;
    height: 25px !important;
    padding: 3px !important;
    border: none !important;
    border-radius: 5px !important;
    overflow: hidden !important;
}

// bell icon button style

.bellIconButton{
    position: relative;
}
.bellIconNumber{
    position: absolute;

    position: absolute;
    // background: red;
    border-radius: 50%;
    font-size: 12px;
    padding: 0px 6px;
    top: -8px;
    left: 14px;
    color: red;
    font-weight: 600;
    max-width: 50px;
    overflow: hidden;

}